import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import * as RootNavigation from "../RootNavigation";
import * as Sentry from "sentry-expo";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toast } from "react-toastify";
import generateGradient from "../functions/generateGradient";

const onErr = async (err, route) => {
  console.log(`${route} error`);
  console.log(err);
  const userIdentification = await AsyncStorage.getItem("userId");
  Sentry.Browser.configureScope(function (scope) {
    scope.setExtra("function_name", route);
    scope.setExtra("id", userIdentification);
    Sentry.Browser.captureException(err);
  });
  toast.error("Having trouble connecting to the server... Try again in a few.");
};

const calcTabs = (content) => {
  const tabs = [];

  if (content.letter != null && content.letter != "") {
    tabs.push({
      order: 0,
      type: 0,
      title: "Overview",
    });
  }

  for (let i = 0; i < content.length; i++) {
    tabs.push({
      order: tabs.length,
      type: 1, // GOAL
      title: content[i].goalTitle,
      content: content[i].goal,
    });

    const goalOrder = tabs.length - 1;
    let stepOrder = 0;

    const stepArr = content[i].steps;
    for (let ii = 0; ii < stepArr.length; ii++) {
      tabs.push({
        order: tabs.length,
        type: 2, // STEP
        goalOrder: goalOrder,
        stepNumber: stepOrder,
        title: stepArr[ii].stepTitle,
        description: stepArr[ii].stepDescription,
        content: stepArr[ii].step,
      });
      stepOrder++;
    }
  }

  tabs.push({
    order: tabs.length,
    type: 3, // NEXT STEPS
    title: "Next Steps",
    content: content.nextSteps,
  });

  return tabs;
};

const genGradients = (number) => {
  let gradients = [];
  for (let i = 0; i < number; i++) {
    gradients.push(generateGradient());
  }
  return gradients;
};

const planReducer = (state, action) => {
  switch (action.type) {
    case "fetch_plan":
      return {
        ...state,
        plan: action.payload,
        tabs: calcTabs(action.payload.content),
        gradients: genGradients(calcTabs(action.payload.content).length),
      };
    case "select_tab":
      return {
        ...state,
        selectedTab: action.payload,
      };
    case "reset":
      return {
        plan: null,
        selectedTab: 0,
      };
    default:
      return state;
  }
};

const fetchPlan = (dispatch) => async (planId) => {
  try {
    const response = await serverApi.post("/plan", { planId: planId });
    dispatch({ type: "fetch_plan", payload: response.data });
  } catch (err) {
    onErr(err, "fetchPlan");
  }
};

const selectTab = (dispatch) => async (tab) => {
  dispatch({ type: "select_tab", payload: tab });
};

const resetPlanContext = (dispatch) => async () => {
  dispatch({ type: "reset" });
};

export const { Provider, Context } = createDataContext(
  planReducer,
  {
    fetchPlan,
    selectTab,
    resetPlanContext,
  },
  {
    plan: {
      company: {
        _id: "example-belvo-id",
        name: "Belvo",
        logo: "https://i.ibb.co/7WmspGP/c6318f41da581f3c72a7ea417223d07eeecc7075.png",
        email: "SUPPORT@BELVO.COM",
        lightColor: "#4a8fff",
        darkColor: "#031e4a",
        headingColor: "#b8d3ff",
        fontImport: "Space Grotesk:300,400,800",
        bodyFont: "Space Grotesk",
        headingFont: "Space Grotesk:300",
      },
      prospectCompany: "Acme, Inc.",
      prospectName: "John",
      apiKey: "sk_S443fghhwddLCjLk3cVK",
      letter:
        "I've gone ahead and mapped out an integration plan according to your needs, and generated a Sandbox API Key for you.\nThis will closely resemble the steps your engineering team will take to integrate Belvo into your lending risk stack.\nIf you have any questions don’t hesitate to reach out directly.\nBest, The Belvo Team",
      // prospectLogo:
      //   "https://deferit.com/images/deferit-public-logo.0a942594d2efc871d81f7b6491b8f9df4946838839feb1d394ba8c05cd61d75d.svg",
      // prospectIcon:
      //   "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/om6pejoyjy22x28dqika",

      content: [
        {
          goalTitle: "Improve Risk Decisions",
        },
      ],
    },
    tabs: [
      {
        order: 0,
        type: 0,
        title: "Overview",
      },
      {
        order: 1,
        type: 1,
        title: "Perform a Credit Risk Assessment",
      },
      {
        order: 2,
        type: 2,
        goalOrder: 1,
        stepNumber: 1,
        title: "Connect a User",
        dbTitle: "connect",
        description: `Try out the Belvo connect process! We have the frontend UI widgets to get your connect flow up and running in minutes.`,
        content: {
          type: 2,
          data: [
            {
              id: "id_american_express",
              parent_name: "American Express",
              name: "American Express",
              logo: "https://s1.q4cdn.com/692158879/files/images/brand_imagery/American-Express-Logotype-Stacked.png",
              description: null,
              note: null,
              types: ["television_utility", "internet_utility"],
              account_prefixes: [],
              provider_ids: {
                plaid: [],
                mx: [],
                finicity: [],
              },
              customized_auth: false,
            },
            {
              id: "id_citibanamex",
              parent_name: "CitiBanamex",
              name: "CitiBanamex",
              logo: "https://www.centrocitibanamex.com/wp-content/uploads/2017/07/logo-citibanamex_form.png",
              description: null,
              note: null,
              types: ["waste_utility"],
              account_prefixes: [],
              provider_ids: {
                plaid: [],
                mx: [],
                finicity: [],
              },
              customized_auth: false,
            },
            {
              id: "id_banco_azteca",
              parent_name: "Banco Azteca",
              name: "Banco Azteca",
              logo: "https://www.kindpng.com/picc/m/609-6098206_banco-azteca-hd-png-download.png",
              description: null,
              note: null,
              types: ["electric_utility", "water_utility", "waste_utility"],
              account_prefixes: [],
              provider_ids: {
                plaid: [],
                mx: [],
                finicity: [],
              },
              customized_auth: false,
            },
            {
              id: "id_bbva_bancomer",
              parent_name: "BBVA Bancomer",
              name: "BBVA Bancomer",
              logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/BBVA_Bancomer_logo.svg/2560px-BBVA_Bancomer_logo.svg.png",
              description: null,
              note: null,
              types: ["electric_utility"],
              account_prefixes: [],
              provider_ids: {
                plaid: [],
                mx: [],
                finicity: [],
              },
              customized_auth: false,
            },
            {
              id: "id_bancoppel",
              parent_name: "BanCoppel",
              name: "BanCoppel",
              logo: "https://logowik.com/content/uploads/images/bancoppel8334.jpg",
              description: null,
              note: null,
              types: ["waste_utility"],
              account_prefixes: [],
              provider_ids: {
                plaid: [],
                mx: [],
                finicity: [],
              },
              customized_auth: false,
            },
            {
              id: "id_banorte",
              parent_name: "Banorte",
              name: "Banorte",
              logo: "https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/012019/untitled-2_7.jpg?tLOTTHDBxxvkuyc.boSEORjbqHcUeFo9&itok=dHExztue",
              description: null,
              note: null,
              types: ["electric_utility"],
              account_prefixes: [],
              provider_ids: {
                plaid: [],
                mx: [],
                finicity: [],
              },
              customized_auth: false,
            },
            {
              id: "id_banregio",
              parent_name: "Banregio",
              name: "Banregio",
              logo: "https://www.brands.mx/system/images/4067/original/Brands_People_Banregio_logo.png?1513356705",
              description: null,
              note: null,
              types: ["water_utility"],
              account_prefixes: [],
              provider_ids: {
                plaid: [],
                mx: [],
                finicity: [],
              },
              customized_auth: false,
            },
            {
              id: "id_hey_banco",
              parent_name: "Hey, Banco",
              name: "Hey, Banco",
              logo: "https://www.brands.mx/system/images/5160/original/Brands_People_Hey_Banco_Wordmark.png?1533658242",
              description: null,
              note: null,
              types: ["electric_utility"],
              account_prefixes: [],
              provider_ids: {
                plaid: [],
                mx: [],
                finicity: [],
              },
              customized_auth: false,
            },
            {
              id: "id_hsbc",
              parent_name: "HSBC",
              name: "HSBC",
              logo: "https://logos-world.net/wp-content/uploads/2021/02/HSBC-Logo.png",
              description: null,
              note: null,
              types: ["credit_card"],
              account_prefixes: [],
              provider_ids: {
                plaid: ["ins_128684"],
                mx: [],
                finicity: [],
              },
              customized_auth: false,
            },
            {
              id: "id_inbursa",
              parent_name: "Inbursa",
              name: "Inbursa",
              logo: "https://upload.wikimedia.org/wikipedia/en/thumb/1/1a/Inbursa_logo.svg/640px-Inbursa_logo.svg.png",
              description: null,
              note: null,
              types: ["electric_utility"],
              account_prefixes: [],
              provider_ids: {
                plaid: [],
                mx: [],
                finicity: [],
              },
              customized_auth: false,
            },
            {
              id: "id_santander",
              parent_name: "Santander",
              name: "Santander",
              logo: "https://logos-world.net/wp-content/uploads/2020/11/Santander-Logo.png",
              description: null,
              note: null,
              types: ["utility"],
              account_prefixes: [],
              provider_ids: {
                plaid: [],
                mx: [],
                finicity: [],
              },
              customized_auth: false,
            },
            {
              id: "id_scotiabank",
              parent_name: "Scotiabank",
              name: "Scotiabank",
              logo: "https://logos-world.net/wp-content/uploads/2021/03/Scotiabank-Logo.png",
              description: null,
              note: null,
              types: ["electric_utility"],
              account_prefixes: [],
              provider_ids: {
                plaid: [],
                mx: [],
                finicity: [],
              },
              customized_auth: false,
            },
          ],
        },
      },
      {
        order: 3,
        type: 2,
        goalOrder: 1,
        stepNumber: 2,
        title: "Account Information",
        dbTitle: "accounts",
        description: `By looking at the accounts your client has, you can get the high-level view of their current assets and liabilities. It's the first step for your risk analysis, and it'll allow you to retrieve: 1) the current available balance of their checking or savings accounts, 2) the current balance of their credit cards, and 3) any loans that your client may have.`,
        content: {
          type: 0,
          firstParam: "type",
          lastParam: "dob",
          codeSnippet: `curl --request GET
          --url https://sandbox.belvo.com/api/accounts/id/
          --header 'Accept: application/json'`,
          requestFields: [
            {
              key: "id",
              value: "f7a4346d",
              type: 1,
              desc: "Account ID",
              preloadKey: "connect.id",
              preloadDesc: "connect.parent_name",
            },
          ],
          botDetails: [
            {
              title: "Verifying Bank",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
                {
                  key: "Account ID",
                  value: "3829382",
                },
              ],
            },
            {
              title: "KYC Verification",
              sub: [
                {
                  key: "Status",
                  value: "Verified",
                },
              ],
            },
            {
              title: "Encrypting Data",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
              ],
            },
          ],
          payload: {
            id: "c21f3914-bcbe-44c4-a2e8-a5e33f6888d4",
            link: "57f212dc-1ba4-407f-b7f0-15a5e5ff17ae",
            institution: {
              name: "erebor_mx_retail",
              type: "bank",
            },
            collected_at: "2022-02-09T08:45:50.406032Z",
            created_at: "2022-02-09T08:46:20.406032Z",
            category: "CHECKING_ACCOUNT",
            balance_type: "ASSET",
            type: "Cuentas de efectivo",
            name: "Cuenta Perfiles- M.N.- - ERB-237",
            number: 2180700688677950,
            balance: {
              available: 4523.48,
              current: 4523.48,
            },
            currency: "MXN",
            bank_product_id: "66",
            internal_identification: "1",
            public_identification_name: "CLABE",
            public_identification_value: 2180700008677950,
            last_accessed_at: "2022-02-01T20:25:47.307911Z",
            credit_data: null,
            loan_data: null,
            funds_data: null,
            gig_payment_data: null,
          },
          success: {
            order: 3,
            componentType: 0, // Entity
            visualView: 1,
            headerIcon: "briefcase",
            header: `"Checking Account"`,
            subHeader: `localDatabase.connect.parent_name`,
            successLabel: `"Account Linked"`,
            data: [
              {
                key: "Assets",
                value: `localDatabase.accounts.balance.available + " " + localDatabase.accounts.currency`,
              },
            ],
          },
        },
      },
      {
        order: 4,
        type: 2,
        goalOrder: 1,
        stepNumber: 3,
        title: "Balance Information",
        dbTitle: "balances",
        description: `By checking the balances for your customer's checking or savings accounts for the last 90 days, you can evaluate how well your client manages their finances over a period of time.`,
        content: {
          type: 0,
          firstParam: "type",
          lastParam: "dob",
          codeSnippet: `curl --request POST
          --url https://sandbox.belvo.com/api/balances/
          --header 'Accept: application/json'
          --header 'Content-Type: application/json'
          --data '
            {
              "save_data": true,
              "link": "2ccd5e15-194a"
            }
            '`,
          requestFields: [
            {
              key: "link",
              value: "2ccd5e15-194a",
              type: 1,
              desc: "Account ID",
              preloadKey: "connect.id",
              preloadDesc: "connect.parent_name",
            },
          ],
          botDetails: [
            {
              title: "Connecting to Bank",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
                {
                  key: "Bank ID",
                  value: "3829382",
                },
              ],
            },
            {
              title: "Accessing Data",
              sub: [
                {
                  key: "Status",
                  value: "Verified",
                },
              ],
            },
            {
              title: "Parsing Data",
            },
            {
              title: "Enriching Data",
            },
          ],
          payload: {
            id: "076c66e5-90f5-4e01-99c7-50e32f65ae42",
            account: {
              id: "0d3ffb69-f83b-456e-ad8e-208d0998d71d",
              link: "30cb4806-6e00-48a4-91c9-ca55968576c8",
              institution: {
                name: "erebor_mx_retail",
              },
              collected_at: "2019-09-27T13:01:41.941Z",
              created_at: "2022-02-09T08:45:50.406032Z",
              category: "CHECKING_ACCOUNT",
              balance_type: "string",
              type: "Cuentas de efectivo",
              name: "Cuenta Perfiles- M.N. - MXN-666",
              number: 4057068115181,
              balance: {
                current: 5874.13,
                available: 5621.12,
              },
              currency: "MXN",
              public_identification_name: "CLABE",
              public_identification_value: "150194683119900273",
              last_accessed_at: "2021-03-09T10:28:40.000Z",
              credit_data: {
                credit_limit: 192000,
                collected_at: "2019-09-27T13:01:41.941Z",
                cutting_date: "2019-12-11",
                next_payment_date: "2019-12-13",
                minimum_payment: 2400.3,
                no_interest_payment: 2690.83,
                interest_rate: 4,
              },
              loan_data: {
                collected_at: "2022-02-09T08:45:50.406032Z",
                contract_amount: 202000,
                principal: 192000,
                loan_type: "Consignado",
                payment_day: "27",
                outstanding_principal: 142023,
                outstanding_balance: 182000,
                monthly_payment: 1000,
                interest_rates: [
                  {
                    name: "jurosEfetivo",
                    type: "MONTHLY",
                    value: 7.85,
                  },
                ],
                fees: [
                  {
                    type: "OPERATION_FEE",
                    value: 5.6,
                  },
                ],
                number_of_installments_total: 60,
                number_of_installments_outstanding: 48,
                contract_start_date: "2020-03-01",
                contract_end_date: "2027-10-01",
                contract_number: "890ASLDJF87SD00",
              },
              funds_data: [
                {
                  name: "FIX X",
                  type: "CNPJ",
                  public_identifiers: [
                    {
                      name: "CNPJ",
                      value: "05.954.445/0221-68",
                    },
                  ],
                  balance: "88427.94",
                  percentage: "100",
                },
              ],
              gig_payment_data: {
                method: "BANK_ACCOUNT",
                issuer: "AZTECA - BANCO AZTECA SA",
                number: "123184016786312246",
                holder_name: "Elisa Rojas Rivas",
                collected_at: "2019-09-27T13:01:41.941Z",
              },
            },
            value_date: "2019-10-23",
            balance: 50000,
          },
          success: {
            componentType: 3, // Data Visualization
            headerIcon: "bar-chart",
            header: `"Balance Management"`,
            subHeader: `localDatabase.connect.parent_name`,
            successLabel: `"Balances Retrieved"`,
            data: [
              {
                name: "6/04",
                Bills: 4000,
                Credit: 2400,
                amt: 2400,
              },
              {
                name: "6/05",
                Bills: 3000,
                Credit: 1398,
                amt: 2210,
              },
              {
                name: "6/06",
                Bills: 2000,
                Credit: 9800,
                amt: 2290,
              },
              {
                name: "6/07",
                Bills: 2780,
                Credit: 3908,
                amt: 2000,
              },
              {
                name: "6/08",
                Bills: 1890,
                Credit: 4800,
                amt: 2181,
              },
              {
                name: "6/09",
                Bills: 2390,
                Credit: 3800,
                amt: 2500,
              },
              {
                name: "6/10",
                Bills: 3490,
                Credit: 4300,
                amt: 2100,
              },
            ],
          },
        },
      },
      {
        order: 5,
        type: 2,
        goalOrder: 1,
        stepNumber: 4,
        title: "Income Information",
        dbTitle: "income",
        description: `By looking at your clients incomes, you can confirm: The type of income your client receives, the amount they receive, and the frequency that they receive it.`,
        content: {
          type: 0,
          firstParam: "type",
          lastParam: "dob",
          codeSnippet: `curl --request POST
          --url https://sandbox.belvo.com/api/incomes/
          --header 'Accept: application/json'
          --header 'Content-Type: application/json'
          --data '
            {
              "save_data": true,
              "link": "2ccd5e15-194a"
            }
            '`,
          requestFields: [
            {
              key: "link",
              value: "2ccd5e15-194a",
              type: 1,
              desc: "Account ID",
              preloadKey: "connect.id",
              preloadDesc: "connect.parent_name",
            },
          ],
          botDetails: [
            {
              title: "Connecting to Bank",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
                {
                  key: "Bank ID",
                  value: "3829382",
                },
              ],
            },
            {
              title: "Accessing Incoming Funds",
              sub: [
                {
                  key: "Status",
                  value: "Verified",
                },
              ],
            },
            {
              title: "Analyzing Data",
            },
            {
              title: "Parsing Income",
            },
          ],
          payload: {
            id: "076c66e5-90f5-4e01-99c7-50e32f65ae42",
            account: {
              id: "0d3ffb69-f83b-456e-ad8e-208d0998d71d",
              link: "30cb4806-6e00-48a4-91c9-ca55968576c8",
              institution: {
                name: "erebor_mx_retail",
              },
              collected_at: "2019-09-27T13:01:41.941Z",
              created_at: "2022-02-09T08:45:50.406032Z",
              category: "CHECKING_ACCOUNT",
              balance_type: "string",
              type: "Cuentas de efectivo",
              name: "Cuenta Perfiles- M.N. - MXN-666",
              number: 4057068115181,
              balance: {
                current: 5874.13,
                available: 5621.12,
              },
              currency: "MXN",
              public_identification_name: "CLABE",
              public_identification_value: "150194683119900273",
              last_accessed_at: "2021-03-09T10:28:40.000Z",
              credit_data: {
                credit_limit: 192000,
                collected_at: "2019-09-27T13:01:41.941Z",
                cutting_date: "2019-12-11",
                next_payment_date: "2019-12-13",
                minimum_payment: 2400.3,
                no_interest_payment: 2690.83,
                interest_rate: 4,
              },
              loan_data: {
                collected_at: "2022-02-09T08:45:50.406032Z",
                contract_amount: 202000,
                principal: 192000,
                loan_type: "Consignado",
                payment_day: "27",
                outstanding_principal: 142023,
                outstanding_balance: 182000,
                monthly_payment: 1000,
                interest_rates: [
                  {
                    name: "jurosEfetivo",
                    type: "MONTHLY",
                    value: 7.85,
                  },
                ],
                fees: [
                  {
                    type: "OPERATION_FEE",
                    value: 5.6,
                  },
                ],
                number_of_installments_total: 60,
                number_of_installments_outstanding: 48,
                contract_start_date: "2020-03-01",
                contract_end_date: "2027-10-01",
                contract_number: "890ASLDJF87SD00",
              },
              funds_data: [
                {
                  name: "FIX X",
                  type: "CNPJ",
                  public_identifiers: [
                    {
                      name: "CNPJ",
                      value: "05.954.445/0221-68",
                    },
                  ],
                  balance: "88427.94",
                  percentage: "100",
                },
              ],
              gig_payment_data: {
                method: "BANK_ACCOUNT",
                issuer: "AZTECA - BANCO AZTECA SA",
                number: "123184016786312246",
                holder_name: "Elisa Rojas Rivas",
                collected_at: "2019-09-27T13:01:41.941Z",
              },
            },
            currency: "MXN",
            sources: [
              {
                transactions: [
                  {
                    amount: 2145.45,
                    value_date: "2019-10-23T00:00:00.000Z",
                    description: "NOMINA BUDDHAS RFC:XXXXXXXXXX",
                  },
                ],
                confidence: "HIGH",
                type: "SALARY",
                frequency: "MONTHLY",
                std_transaction_amount: 1040.83,
                average_transaction_amount: 31166.66,
                median_transaction_amount: 31500,
                minimum_transaction_amount: 30000,
                maximum_transaction_amount: 32000,
                days_since_last_transaction: 6,
                average_days_between_consecutive_income_transactions: 30.5,
                std_days_between_consecutive_income_transactions: 2.12,
                median_days_between_consecutive_income_transactions: 30.5,
                minimum_days_between_consecutive_income_transactions: 29,
                maximum_days_between_consecutive_income_transactions: 32,
              },
            ],
            aggregations: [
              {
                period_unit: "MONTH",
                type: "ALL",
                minimum_confidence: "HIGH",
                lookback_periods: 3,
                full_periods: 2,
                periods_with_income: 3,
                total_number_of_sources: 3,
                std_income_per_period: 1415.12,
                average_income_per_period: 2742.07,
                median_income_per_period: 1990.73,
                maximum_income_per_period: 4374.4,
                minimum_income_per_period: 1861.08,
              },
            ],
          },
          success: {
            order: 5,
            componentType: 0, // Entity
            visualView: 1,
            headerIcon: "cash",
            header: `"Income Analysis"`,
            subHeader: `localDatabase.connect.parent_name`,
            successLabel: `"Income Analyzed"`,
            data: [
              {
                key: "MONTHLY INCOME",
                value: `localDatabase.income.aggregations[0].average_income_per_period + " " + localDatabase.income.currency`,
              },
            ],
          },
        },
      },
      {
        order: 6,
        type: 2,
        goalOrder: 1,
        stepNumber: 5,
        title: "Analyze Spending Habits",
        dbTitle: "transactions",
        description: `By looking at the transactions your customer has made in the last 90 days, you can get a great idea of their spending habits over a given period of time.`,
        content: {
          type: 0,
          firstParam: "type",
          lastParam: "dob",
          codeSnippet: `curl --request POST
          --url https://sandbox.belvo.com/api/transactions/
          --header 'Accept: application/json'
          --header 'Content-Type: application/json'
          --data '
            {
              "save_data": true,
              "link": "2ccd5e15-194a"
            }
            '`,
          requestFields: [
            {
              key: "link",
              value: "2ccd5e15-194a",
              type: 1,
              desc: "Account ID",
              preloadKey: "connect.id",
              preloadDesc: "connect.parent_name",
            },
          ],
          botDetails: [
            {
              title: "Filtering Charges",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
              ],
            },
            {
              title: "Cross-Referencing Amounts",
              sub: [
                {
                  key: "Status",
                  value: "Verified",
                },
              ],
            },
            {
              title: "Running Data Analysis",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
              ],
            },
          ],
          payload: {
            id: "c21f3914-bcbe-44c4-a2e8-a5e33f6888d4",
            link: "57f212dc-1ba4-407f-b7f0-15a5e5ff17ae",
            institution: {
              name: "erebor_mx_retail",
              type: "bank",
            },
            collected_at: "2022-02-09T08:45:50.406032Z",
            created_at: "2022-02-09T08:46:20.406032Z",
            category: "CHECKING_ACCOUNT",
            balance_type: "ASSET",
            type: "Cuentas de efectivo",
            name: "Cuenta Perfiles- M.N.- - ERB-237",
            number: 2180700688677950,
            balance: {
              available: 4523.48,
              current: 4523.48,
            },
            currency: "MXN",
            bank_product_id: "66",
            internal_identification: "1",
            public_identification_name: "CLABE",
            public_identification_value: 2180700008677950,
            last_accessed_at: "2022-02-01T20:25:47.307911Z",
            credit_data: null,
            loan_data: null,
            funds_data: null,
            gig_payment_data: null,
          },
          success: {
            componentType: 4,
            headerIcon: "aperture",
            header: `"Spending Habits"`,
            subHeader: `localDatabase.connect.parent_name`,
            successLabel: `"Habits Analyzed"`,
            data: [
              { name: "Bills & Utilities", value: 400 },
              { name: "Food & Groceries", value: 300 },
              { name: "Personal Shopping", value: 300 },
              { name: "Credits & Loans", value: 200 },
            ],
          },
        },
      },
      {
        order: 7,
        type: 4,
        title: "All Done!",
        description: `Now that you know the basic data points to get a 360-degree view of your customer, you can augment the data and perform your own custom analysis to evaluate your clients.`,
      },
    ], // FIXME: remove and set to null for prod
    selectedTab: {
      index: 0,
      type: "auto",
    },
    gradients: genGradients(7),
  }
);
