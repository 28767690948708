import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  useWindowDimensions,
  ActivityIndicator,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import {
  Text,
  Input,
  Button,
  Select,
  Card,
  useBodyScroll,
} from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import RequestTabs from "./RequestTabs";
import StepBot from "./StepBot";
import Success from "@components/SuccessComponent/SuccessComponent";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { toast } from "react-toastify";

const Endpoint = ({
  order,
  stepContent,
  scrollRef,
  scrollDown,
  isScrolling,
}) => {
  const {
    state: { gradients, tabs, selectedTab },
  } = useContext(PlanContext);
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const botRef = useRef(null);
  const [didExecute, setDidExecute] = useState(false);
  const [triggerInView, setTriggerInView] = useState(false);
  const [executeTrigger, setExecuteTrigger] = useState(false);
  //const [scrollDown, setScrollDown] = useState(false);
  const [locked, setLocked] = useBodyScroll(scrollRef);
  const [displayTrigger, setDisplayTrigger] = useState(false);

  const topRef = useRef(null);
  const windowDims = useWindowDimensions();

  const checkNullPreloads = () => {
    let nullPreloadVars = [];
    const reqFields =
      tabs[order].content != null && tabs[order].content.requestFields != null
        ? tabs[order].content.requestFields
        : null;
    if (reqFields != null) {
      reqFields.forEach((elem) => {
        if (elem.hasOwnProperty("preloadKey") && elem.preloadKey != null) {
          if (_.get(localDatabase, elem.preloadKey) == null) {
            nullPreloadVars.push(elem);
          }
        }
      });
    }
    return nullPreloadVars.length == 0 ? null : nullPreloadVars;
  };

  const [nullPreloads, setNullPreloads] = useState(checkNullPreloads());

  const lineVariants = {
    visible: { display: "block", height: "auto", opacity: 1 },
    hidden: { display: "none", height: 0, opacity: 0 },
  };

  const handleTrigger = async () => {
    if (isScrolling == null && scrollDown == true) {
      setExecuteTrigger(true);
    }
  };

  useEffect(() => {
    setNullPreloads(checkNullPreloads());
  }, [localDatabase]);

  useEffect(() => {
    if (displayTrigger == true) {
      setLocked(true);
      setTimeout(() => handleTrigger(), 500);
      const resolveAfter3Sec = new Promise((resolve) =>
        setTimeout(resolve, 2000)
      );
      toast.promise(resolveAfter3Sec, {
        pending: "Request pending",
        success: "Request resolved 👌",
        error: "Request rejected 🤯",
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      //handleTrigger();
    }
  }, [displayTrigger]);

  return (
    <View ref={topRef} style={{ width: "100%", minHeight: 1400 }}>
      <InView
        threshold={0.25}
        onChange={(inView) => {
          if (
            inView == false &&
            selectedTab.index == order &&
            scrollDown == true &&
            !didExecute
          ) {
            if (nullPreloads == null) {
              setDisplayTrigger(true);
            }
          }
        }}
        style={{ alignSelf: "center", width: "80%", maxWidth: 600 }}
      >
        <RequestTabs
          order={order}
          botRef={botRef}
          setDidExecute={setDidExecute}
          executeTrigger={executeTrigger}
          checkNullPreloads={checkNullPreloads}
        />
      </InView>

      <motion.div
        animate={{ opacity: didExecute ? 1 : 0 }}
        style={{
          alignSelf: "center",
          width: "100%",
        }}
        ref={botRef}
      >
        <View style={{ alignSelf: "center", marginTop: 20 }}>
          {didExecute ? (
            <motion.div
              variants={lineVariants}
              initial="hidden"
              animate={"visible"}
              transition={{ duration: 2 }}
              style={{ alignSelf: "center" }}
            >
              <View
                style={{
                  width: 1,
                  height: 75,
                  borderLeftWidth: 1,
                  borderLeftColor: "grey",
                  borderStyle: "dashed",
                  alignSelf: "center",
                }}
              />
            </motion.div>
          ) : null}
        </View>

        <View style={{ width: "100%", alignItems: "center" }}>
          <View style={[styles.botContainer, { background: gradients[order] }]}>
            <StepBot
              didExecute={didExecute}
              botDetails={stepContent.content.botDetails}
            />
          </View>
        </View>

        {didExecute ? (
          <View style={{ alignSelf: "center", marginTop: 20 }}>
            <motion.div
              variants={lineVariants}
              initial="hidden"
              animate={"visible"}
              transition={{ duration: 1, delay: [1, 2, 3].length * 1 + 0.5 }}
              style={{ alignSelf: "center" }}
            >
              <View
                style={{
                  width: 1,
                  height: 75,
                  borderLeftWidth: 1,
                  borderLeftColor: "grey",
                  borderStyle: "dashed",
                  alignSelf: "center",
                }}
              />
            </motion.div>
            <View style={{ marginVertical: 20, width: "100%" }}>
              <motion.div
                variants={lineVariants}
                onAnimationComplete={() => setLocked(false)}
                initial="hidden"
                animate={"visible"}
                transition={{ duration: 1, delay: [1, 2, 3].length * 1 + 1 }}
                style={{
                  alignSelf: "center",
                  width:
                    stepContent.content.success.componentType == 3 ||
                    stepContent.content.success.componentType == 4
                      ? "80%"
                      : null,
                }}
              >
                <Success data={stepContent.content.success} />
              </motion.div>
            </View>
          </View>
        ) : null}
      </motion.div>
    </View>
  );
};

const styles = StyleSheet.create({
  botContainer: {
    alignSelf: "center",
    width: "80%",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 600,
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 5,
    padding: 35,
  },
  botBackground: {
    position: "absolute",
    borderRadius: 5,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    zIndex: -1,
  },
  dashedLine: {
    alignSelf: "center",
    borderLeftWidth: 1,
    borderStyle: "dashed",
    height: 100,
    width: 0,
  },
});

export default Endpoint;
