import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
  Image,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { useMediaQuery } from "react-responsive";
import { Text, Button, Tag, Card, Input, Table } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import JsxParser from "react-jsx-parser";
import RemoteImage from "../../../../../components/RemoteImage";
import Success from "@components/SuccessComponent/SuccessComponent";

const Goal = () => {
  const {
    state: { plan },
  } = useContext(PlanContext);
  const scrollRef = useRef(null);
  const [animating, setAnimating] = useState(false);

  const successOneData = {
    order: 3,
    componentType: 0, // Entity
    visualView: 1,
    headerIcon: "briefcase",
    header: `"Checking Account"`,
    subHeader: `"Wells Fargo"`,
    successLabel: `"Account Linked"`,
    data: [
      {
        key: "Assets",
        value: `"5,235" + " " + "MXN"`,
      },
    ],
  };

  const successTwoData = {};

  const successData = [
    {
      order: 3,
      componentType: 0, // Entity
      visualView: 1,
      headerIcon: "briefcase",
      header: `"Checking Account"`,
      subHeader: `"Wells Fargo"`,
      successLabel: `"Account Linked"`,
      data: [
        {
          key: "Assets",
          value: `"5,235" + " " + "MXN"`,
        },
      ],
    },
    {
      componentType: 3, // Data Visualization
      headerIcon: "bar-chart",
      header: `"Balance Management"`,
      subHeader: `"Wells Fargo"`,
      successLabel: `"Balances Retrieved"`,
      data: [
        {
          name: "6/04",
          Bills: 4000,
          Credit: 2400,
          amt: 2400,
        },
        {
          name: "6/05",
          Bills: 3000,
          Credit: 1398,
          amt: 2210,
        },
        {
          name: "6/06",
          Bills: 2000,
          Credit: 9800,
          amt: 2290,
        },
        {
          name: "6/07",
          Bills: 2780,
          Credit: 3908,
          amt: 2000,
        },
        {
          name: "6/08",
          Bills: 1890,
          Credit: 4800,
          amt: 2181,
        },
        {
          name: "6/09",
          Bills: 2390,
          Credit: 3800,
          amt: 2500,
        },
        {
          name: "6/10",
          Bills: 3490,
          Credit: 4300,
          amt: 2100,
        },
      ],
    },
    {
      order: 5,
      componentType: 0, // Entity
      visualView: 1,
      headerIcon: "cash",
      header: `"Income Analysis"`,
      subHeader: `"Wells Fargo"`,
      successLabel: `"Income Analyzed"`,
      data: [
        {
          key: "MONTHLY INCOME",
          value: `"2,556 MXN"`,
        },
      ],
    },
    {
      componentType: 4,
      headerIcon: "aperture",
      header: `"Spending Habits"`,
      subHeader: `"Wells Fargo"`,
      successLabel: `"Habits Analyzed"`,
      data: [
        { name: "Bills & Utilities", value: 400 },
        { name: "Food & Groceries", value: 300 },
        { name: "Personal Shopping", value: 300 },
        { name: "Credits & Loans", value: 200 },
      ],
    },
  ];

  const componentVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View style={{ width: "100%" }}>
          <Text
            style={{
              color: plan.company.headingColor,
              marginBottom: -10,
              fontFamily:
                plan.company.headingFont != null
                  ? plan.company.headingFont
                  : null,
            }}
            h1
          >
            Mission
          </Text>
          <Text
            style={{
              color: plan.company.darkColor,
              marginBottom: -10,
              fontFamily:
                plan.company.headingFont != null
                  ? plan.company.headingFont
                  : null,
            }}
            h1
          >
            Perform a Credit Risk Assessment
          </Text>
          <View style={{ marginVertical: 15 }} />
          <Text style={{ color: plan.company.darkColor }} font="16px">
            With just a few API calls you can quickly get the data your need to
            perform a credit risk assessment of your client, replacing any
            manual and error-prone processes.
          </Text>
          <View style={{ marginVertical: 10 }} />
          <View style={{ alignSelf: "flex-start" }}>
            <Button
              style={{ fontWeight: "bold" }}
              type="secondary"
              onClick={() =>
                // lastComp.current.scrollIntoView({
                //   behavior: "smooth",
                // })
                {
                  setAnimating(true);
                }
              }
            >
              Start Animation
            </Button>
          </View>
        </View>
      </View>
      <View style={{ marginVertical: 20 }} />
      <View style={styles.scrollContainer}>
        <ScrollView
          ref={scrollRef}
          horizontal
          contentContainerStyle={styles.animationContainer}
        >
          <motion.div
            style={{
              alignSelf: "center",
              height: 500,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View
              style={[
                styles.databaseView,
                { padding: 50, justifyContent: "center", alignSelf: "center" },
              ]}
            >
              <Image
                source={require("../../../../../../assets/images/lock-dynamic-color.png")}
                style={{
                  height: 200,
                  width: 200,
                  alignSelf: "center",
                }}
              />
              <View style={{ marginVertical: 10 }} />
              <Text style={{ textAlign: "center" }} h3>
                Authentication
              </Text>
            </View>
          </motion.div>

          <motion.div
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            style={{ alignSelf: "center" }}
          >
            <View style={styles.nextBorder} />
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            style={{ alignSelf: "center", height: 500 }}
            transition={{ delay: 0.2 }}
          >
            <Success data={successData[0]} />
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            style={{ alignSelf: "center" }}
            transition={{ delay: 0.8 }}
          >
            <View style={styles.nextBorder} />
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            transition={{ delay: 0.8 }}
            style={{ alignSelf: "center", width: 400, height: 500 }}
          >
            <Success data={successData[1]} height={500} />
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            transition={{ delay: 1.2 }}
            style={{ alignSelf: "center" }}
          >
            <View style={styles.nextBorder} />
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            transition={{ delay: 1.2 }}
            style={{ alignSelf: "center", height: 500 }}
          >
            <Success data={successData[2]} />
          </motion.div>

          <motion.div
            onAnimationComplete={() => {
              if (animating)
                scrollRef.current.scrollBy({
                  top: 0,
                  left: +1000,
                  behavior: "smooth",
                });
            }}
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            transition={{ delay: 1.6 }}
            style={{ alignSelf: "center" }}
          >
            <View style={styles.nextBorder} />
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            transition={{ delay: 1.6 }}
            style={{ alignSelf: "center", width: 400, height: 500 }}
          >
            <Success data={successData[3]} height={500} />
          </motion.div>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  scrollContainer: {
    borderWidth: 1,
    borderColor: "lightgrey",
    borderRadius: 10,
  },
  animationContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    minWidth: "100%",
    paddingVertical: 40,
    paddingHorizontal: 25,
  },
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
  nextBorder: {
    height: 0,
    width: 75,
    borderTopWidth: 1,
    borderTopColor: "grey",
    borderStyle: "dashed",
    alignSelf: "center",
    marginHorizontal: 20,
  },
  // ...JSON.parse(loadedStyles),
});

export default Goal;
