import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import "./devices.min.css";
import RemoteImage from "../../RemoteImage";

const MemoImage = React.memo(function ({ source, style }) {
  return <Image source={source} style={style} />;
});

const Connect = ({ order, stepContent }) => {
  const {
    selectTab,
    state: { plan },
  } = useContext(PlanContext);
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const [stage, setStage] = useState(0);

  const onSelect = (merchant) => {
    modDatabase({ key: "connect", value: merchant });
  };

  const advanceStage = () => {
    setStage(stage + 1);
  };

  const phoneElem = (content) => {
    return (
      <div className="marvel-device iphone-x" style={{ "--size-divisor": 1.2 }}>
        <div className="notch">
          <div className="camera"></div>
          <div className="speaker"></div>
        </div>
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="bottom-bar"></div>
        <div className="volume"></div>
        <div className="overflow">
          <div className="shadow shadow--tr"></div>
          <div className="shadow shadow--tl"></div>
          <div className="shadow shadow--br"></div>
          <div className="shadow shadow--bl"></div>
        </div>
        <div className="inner-shadow"></div>
        <div className="screen">{content}</div>
      </div>
    );
  };

  const step0 = () => (
    <View
      style={{
        width: "100%",
        height: "100%",
        paddingVertical: 75,
        paddingHorizontal: 20,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <View style={{ alignItems: "center" }}>
        <RemoteImage
          desiredWidth={150}
          uri="https://i.ibb.co/NVPHq1w/header-consent.png"
        />
        <View style={{ marginVertical: 10 }} />
        <Text style={{ textAlign: "center" }}>
          ACME Corp uses Belvo to connect your account
        </Text>
        <View style={{ marginVertical: 10 }} />
        <Text small b>
          Belvo will only be able to read your data
        </Text>
        <View style={{ marginVertical: 5 }} />
        <Text style={{ color: "grey" }} small font="11px">
          ACME Corp won't have access to your credentials or be able to perform
          any action from your account.
        </Text>
      </View>
      <Button width={"100%"} onClick={() => advanceStage()} type="success">
        Continue
      </Button>
    </View>
  );

  const step1 = () => (
    <View
      style={{
        width: "100%",
        height: "100%",
        paddingVertical: 75,
        paddingHorizontal: 20,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <View style={{ alignItems: "center" }}>
        <Text style={{ textAlign: "center" }} b>
          Select a bank
        </Text>
        <View style={{ marginVertical: 10 }} />
        <View
          style={{
            borderColor: "lightgrey",
            borderWidth: 0.5,
            borderRadius: 5,
            marginBottom: 40,
          }}
        >
          <ScrollView
            contentContainerStyle={{
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
              maxHeight: 400,
              paddingVertical: 15,
              paddingHorizontal: 15,
              alignItems: "flex-start",
            }}
          >
            {stepContent.content.data.map((elem) => {
              const isSelected =
                localDatabase.connect != null &&
                `${localDatabase.connect.id}` == `${elem.id}`;

              return (
                <TouchableOpacity
                  key={`${elem.id}`}
                  onPress={() => onSelect(elem)}
                  style={{
                    marginVertical: 10,
                    marginHorizontal: 5,
                    alignItems: "center",
                    justifyContent: "center",
                    borderColor: "lightgrey",
                    borderWidth: 0.5,
                  }}
                >
                  <motion.div whileHover={{ scale: 1.1 }} layout>
                    <MemoImage
                      source={{ uri: elem.logo }}
                      style={{
                        width: 100,
                        height: 50,
                        borderRadius: 5,
                        borderColor: isSelected
                          ? plan.company.darkColor
                          : "#F8F8F8",
                        borderWidth: isSelected ? 4 : 4,
                      }}
                    />
                  </motion.div>
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        </View>
      </View>
      {!localDatabase.connect || localDatabase.connect == null ? (
        <Button width={"100%"} style={{ fontWeight: "bold" }} disabled>
          No Bank Selected
        </Button>
      ) : (
        <Button
          width={"100%"}
          style={{
            padding: 0,
            backgroundColor: plan.company.darkColor,
            borderColor: plan.company.darkColor,
          }}
          type="secondary-light"
          onClick={() => {
            advanceStage();
          }}
        >
          <Text style={{ color: plan.company.lightColor }} b>
            {localDatabase.connect.parent_name} Selected
          </Text>
        </Button>
      )}
    </View>
  );

  const step2 = () => (
    <View
      style={{
        width: "100%",
        height: "100%",
        paddingVertical: 75,
        paddingHorizontal: 20,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <View style={{ alignItems: "center" }}>
        <RemoteImage desiredWidth={75} uri={localDatabase.connect.logo} />
        <View style={{ marginVertical: 20 }} />
        <Input width={"100%"} placeholder="Ej. 123456">
          Client No.
        </Input>
        <View style={{ marginVertical: 20 }} />
        <Input.Password width={"100%"} initialValue="123456abc">
          Password
        </Input.Password>
        <View style={{ marginVertical: 30 }} />
        <Text style={{ color: "grey" }} small font="11px">
          +1M users have securely connected their accounts using Belvo.
        </Text>
      </View>
      <Button
        width={"100%"}
        onClick={() => {
          const resolveAfter3Sec = new Promise((resolve) =>
            setTimeout(resolve, 500)
          );
          toast.promise(resolveAfter3Sec, {
            pending: "Request pending",
            success: "Request resolved 👌",
            error: "Request rejected 🤯",
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          advanceStage();
        }}
        type="success"
      >
        Connect Account
      </Button>
    </View>
  );

  const step3 = () => (
    <View
      style={{
        width: "100%",
        height: "100%",
        paddingVertical: 75,
        paddingHorizontal: 20,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <View style={{ alignItems: "center" }}>
        <RemoteImage
          desiredWidth={150}
          uri="https://i.ibb.co/NVPHq1w/header-consent.png"
        />
        <View style={{ marginVertical: 30 }} />
        <Image
          source={require("../../../../assets/images/sheild-dynamic-gradient.png")}
          style={{
            height: 150,
            width: 150,
            alignSelf: "center",
          }}
        />
        <View style={{ marginVertical: 10 }} />
        <Text style={{ textAlign: "center", width: "70%" }} b>
          Account Connected Successfully
        </Text>
      </View>
      <Button
        width={"100%"}
        onClick={() => {
          selectTab({ index: order + 1, type: "manual" });
        }}
        type="success"
      >
        Finish
      </Button>
    </View>
  );

  return (
    <View
      style={{
        width: "100",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <View style={{ alignSelf: "center" }}>
        {phoneElem(
          <View style={{ height: "100%", width: "100%" }}>
            {stage == 0 ? step0() : null}
            {stage == 1 ? step1() : null}
            {stage == 2 ? step2() : null}
            {stage == 3 ? step3() : null}
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
});

export default Connect;
